import React from "react";
import * as styles from './serviceicon.module.css';

export default class ServiceIcon extends React.Component {

    render() {
        return (
            <div className={styles.container}>
                <img className={styles.icon} src={this.props.image} alt={this.props.title}/>
                <h3 className={styles.title}>{this.props.title}</h3>
                <p className={styles.description}>{this.props.description}</p>
            </div>
        );
    }
}