import 'objectFitPolyfill';

// https://github.com/constancecchen/object-fit-polyfill/issues/54
export const vidHelper = () => {
    const vids = Array.from(document.querySelectorAll('video'));
    vids.forEach(vid => {
        vid.setAttribute('data-object-fit', 'cover');
        //vid.setAttribute('data-object-position', 'center center');
    })
    window.objectFitPolyfill();
};