import React from "react"
import {Helmet} from "react-helmet";

import Header from "../components/header/header";
import Homepage from "../components/homepage/homepage";
import Footer from "../components/footer/footer";

import SocialImage from '../images/social/LogoNeu.jpg';

export default () => (
    <>
        {/*htmlAttributes={{"lang": "de"}}*/}
        <Helmet>
            {/*Primary Meta Tags*/}
            <title>Kronschl&auml;ger Reisen — Busreisen | Taxi | Mietwagen | Krankentransporte</title>
            <meta name="title" content="Kronschl&auml;ger Reisen — Busreisen | Taxi | Mietwagen | Krankentransporte"/>
            <meta name="description"
                  content="Bew&auml;hrt, flexibel, individuell - wir sind Ihr Reisepartner mit Herz und Verstand. Qualit&auml;t f&uuml;r jede Gelegenheit."/>

            {/*Open Graph / Facebook */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.kronschlaeger-reisen.at"/>
            <meta property="og:title"
                  content="Kronschl&auml;ger Reisen — Busreisen | Taxi | Mietwagen | Krankentransporte"/>
            <meta property="og:description"
                  content="Bew&auml;hrt, flexibel, individuell - wir sind Ihr Reisepartner mit Herz und Verstand. Qualit&auml;t f&uuml;r jede Gelegenheit."/>
            <meta property="og:image" content={`https://www.kronschlaeger-reisen.at${SocialImage}`}/>

            {/* Google Bot */}
            <meta name="robots" content="index,follow"/>
        </Helmet>
        <Header overlay={true}/>
        <Homepage/>
        <Footer/>
    </>
);