import React from "react";
import * as  styles from "./homepage.module.css";
import {vidHelper} from "../helpers";
import sliderVideoMp4 from './media/Slider.mp4';
import sliderVideoWebm from './media/Slider.webm';
import ServiceIcon from "./serviceicon/serviceicon";

import ausfluegeImage from './media/ausfluege.png';
import businessImage from './media/business.png';
import busreisenImage from './media/busreisen.png';
import gruppenreisenEuropaImage from './media/gruppenreisen_europa.png';
import krankentransporteImage from './media/krankentransporte.png';
import mietwagenImage from './media/mietwagen.png';
import nachttaxiImage from './media/nachttaxi.png';
import tagesfahrtImage from './media/tagesfahrt.png';
import taxiImage from './media/taxi.png';


export default class Homepage extends React.Component {

    render() {
        return (
            <>
                {/*<img className={styles.galleryImage} src={galleryImage} alt={"Fuhrpark"}/>*/}
                <div className={styles.videoContainer}>
                    <video className={styles.sliderVideo} muted={true} loop={true} autoPlay={true}>
                        <source src={sliderVideoMp4} type="video/mp4"/>
                        <source src={sliderVideoWebm} type="video/webm"/>
                    </video>
                    <div className={"container " + styles.videoTextContainer}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <h2>Kronschl&auml;ger Reisen</h2>
                                <p>Busreisen | Taxi | Mietwagen | Krankentransporte</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container"}>
                    {/*<h2 className={"h1"}>Wir bieten</h2>*/}
                    <div className={"row " + styles.padding}>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={busreisenImage} title={"Ausflüge"} description={"im In- und Ausland"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={taxiImage} title={"Taxi"}
                                         description={"für Einzelpersonen, Gruppen und Senioren"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={mietwagenImage} title={"Miet\u00adwagen"}
                                         description={"Auswahl aus unserer Flotte"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={krankentransporteImage} title={"Kranken\u00adtrans\u00adporte"}
                                         description={"mit Opel Meriva und BMW 5er GT"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={ausfluegeImage} title={"Ausflüge"}
                                         description={"Individuelle Planung"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={tagesfahrtImage} title={"Tages\u00adfahrten"}
                                         description={"für Sommer und Winter"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={nachttaxiImage} title={"Nachttaxi"}
                                         description={"Jugendtaxi, Zeltfestfahrten"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={gruppenreisenEuropaImage} title={"Gruppen\u00adreisen Europa"}
                                         description={"auf Anfrage mit individueller Programmgestaltung"}/>
                        </div>
                        <div className={"col-lg-3 col-md-4 col-sm-6 col-6"}>
                            <ServiceIcon image={businessImage} title={"Business"}
                                         description={"Komfort und Verlässlichkeit"}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }


    componentDidMount() {
        vidHelper();
    }
}